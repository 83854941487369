import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import pressIcon from '../../assets/images/jobbox/press-icon.svg';

import BrilliantlyWarmOne from '../../assets/images/brilliantly-warm/brilliantly-warm-banner-1.webp';
import BrilliantlyWarmTwo from '../../assets/images/brilliantly-warm/brilliantly-warm-banner-2.webp';
import BrilliantlyWarmThreeA from '../../assets/images/brilliantly-warm/brilliantly-warm-banner-3-a.webp';
import BrilliantlyWarmThreeB from '../../assets/images/brilliantly-warm/brilliantly-warm-banner-3-b.webp';
import BrilliantlyWarmFour from '../../assets/images/brilliantly-warm/brilliantly-warm-banner-4.webp';
import BrilliantlyWarmBorder from '../../assets/images/brilliantly-warm/brilliantly-warm-banner.webp';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class BrilliantlyWarm extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  Portable, Full-Body Warmth at a Button’s Press
                </div>
                <div className="subtitle section-margin">
                  Wearable warming technology that keeps you comfortable all day long
                </div>
                <div className="client-title section-margin">
                  CLIENT |<span style={{color: '#AC6795'}}> BRILLIANTLY WARM</span>
                </div>
                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Develop an app-controlled warming wearable to help mastectomy patients from
                      feeling cold and uncomfortable.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Designed a seamless and comfortable wearable that provides up to 7 hours of
                      continuous warmth in a single charge.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>

                    <p className="desc">
                      Brilliantly Warm had a successful product launch in June 2021. It sold out in
                      its presale campaign and was on a second production run of 500 units in 2022.
                    </p>
                  </div>
                </div>
                <div className="press-section section-margin">
                  <div className="d-flex align-items-center">
                    {' '}
                    <img className="icon " src={pressIcon} alt="" />
                    <div className="subheading ml-2">Press</div>
                  </div>
                  <p className="desc">
                    <a
                      className="press-desc"
                      href="https://www.everydayhealth.com/breast-cancer/guide/"
                      target="_blank" rel="noreferrer"
                    >
                      A warming wearable for mastectomy patients
                    </a>{' '}
                    - Everyday Health
                    <br />
                    <a
                      className="press-desc"
                      href="https://www.nasdaq.com/articles/how-kristen-carbone-is-creating-community-for-women-affected-by-breast-cancer-2021-09-24"
                      target="_blank" rel="noreferrer"
                    >
                      How Kristen Carbone is creating community for women affected by breast cancer
                    </a>{' '}
                    - Nasdaq
                  </p>
                </div>

                <div className="video-container section-margin">
                  <iframe
                    src="https://www.youtube.com/embed/fzm7Txjv4fs"
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </div>

                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">
                      Client’s brief background and business problem
                    </div>
                    <p className="description">
                      After a mastectomy or breast tissue procedure, many women experienced waves of
                      intense cold sensations that they could not shake off. The startup{' '}
                      <a
                        className="description"
                        href="https://www.brilliantly.co/"
                        style={{textDecoration: 'underline'}}
                        target="_blank" rel="noreferrer"
                      >
                        Brilliantly
                      </a>{' '}
                      , born out of its founder’s personal experience, and their first product Warm,
                      envisioned a sustainable solution to address this.
                    </p>
                    <br />
                    <p className="description">
                      Brilliantly wanted to build a warming device that could be safely and
                      discreetly inserted inside the undergarments and comfortably worn on any size.
                      The product had to be programmed to allow for a customizable warming
                      experience that could be managed from a user’s phone via Bluetooth.
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100  " src={BrilliantlyWarmOne} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Brilliantly had already been a couple of years into their product development
                      journey when they engaged Tintash. Their in-house team had worked on a warming
                      pad that fulfilled the design requirements, including dimensions, thickness,
                      and fabric selection. They were now looking for a hardware engineering partner
                      who could build a control unit which could be managed through an app and which
                      regulated the temperature of the warming pad. Brilliantly partnered with
                      Tintash to deliver a production-ready design for Warm.
                    </p>
                    <br />
                    <p className="description">
                      Our work was divided between developing a heater board and a charging dock.
                      The pad had already been designed to pass dupont conductive ink through it.
                      Passing this current would create a resistive heating element that increased
                      the device’s temperature. Our job was to regulate this temperature to ensure
                      it didn’t cause a burning sensation.
                    </p>
                    <br />
                    <p className="description">
                      In the erstwhile template, the temperature sensor had been placed at a point
                      not in contact with the heating element. Since the ink wasn’t passing anywhere
                      near the sensor, it was unable to correctly measure the whole pad’s
                      temperature. We iterated on the design to create a mesh of the heating element
                      (heater trace in image below) to ensure the heating was spread across the
                      pad’s surface area. We then placed the sensor on that mesh so that it would
                      correctly measure the temperature felt by the user.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img className="w-100  " src={BrilliantlyWarmTwo} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      To regulate the temperature when heating the element, we made use of rhythms
                      (fixed patterns e.g. 20 secs on, 10 secs off) which were provided by the
                      client. These rhythms, however, were customizable allowing users to set their
                      own device settings. In order to ensure user safety, we included fail-safe
                      triggers to prevent excess current from passing through the warmer and heating
                      it beyond a safe temperature.
                    </p>
                    <br />
                    <p className="description">
                      Tintash also developed an iOS mobile app for users to set their preferred
                      warming cycles. The app, however, wasn’t exclusive to the device’s functioning
                      i.e. once users had programmed the rhythm, they could run the device without
                      the app. These regulatory rhythms, besides helping manage the temperature,
                      also allowed for energy conservation and prolonging the battery life.
                    </p>
                    <br />
                    <p className="description">
                      The processor we used was nRF52 (Nordic semiconductor), a Bluetooth-powered
                      device which could communicate with the app and report the pad’s temperature.
                      Since the device was wearable, our choice of processor was guided by its
                      compact size and lightweight.
                    </p>
                    <br />
                    <p className="description">
                      Our hardware team worked in-house on the schematic, the PCB design and the
                      layout for V1 of the device. Once finalized, we had a small number of boards
                      fabricated from China and shipped to our office. Next up, the team did the
                      board bring up and modular testing before dispatching the fully functional and
                      verified boards for testing by the client in the US.
                    </p>
                    <br />
                    <p className="description">
                      Tintash also assigned a technical project manager (TPM) to the project
                      responsible for sprint planning and execution, defining milestones, and
                      ensuring their timely delivery. The TPM maintained direct contact with the
                      product managers at Brilliantly to provide consultation, gather feedback, and
                      schedule demos of the completed milestones.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-6 p-0">
                      <img
                        className="w-100"
                        src={BrilliantlyWarmThreeA}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-6 p-0">
                      <img
                        className="w-100"
                        src={BrilliantlyWarmThreeB}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      Brilliantly Warm was a new, patent pending technology that delivered
                      natural-feeling warmth for the whole body. It was safe and effective, with a
                      temperature regulated and tested for all-day wear. Its cordless and seamless
                      design made it easy and comfortable to use.
                    </p>
                    <br />
                    <p className="description">
                      The device could be easily managed via a user-friendly app that contained
                      three bespoke warming cycles. It contained a rechargeable battery which took
                      1.5 hours to completely recharge (via Micro USB) and could provide up to 7
                      hours of warming on a single charge.
                    </p>
                    <br />
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin">
                    <div className="col-lg-12">
                      <img
                        className="w-100  "
                        src={BrilliantlyWarmFour}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <p className="description">
                      It took Brilliantly around four years in Warm’s R&D. Upon its eventual launch
                      in June 2021, the product completely sold out in its presale campaign and was
                      on its second production run of an additional 500 units in 2022.
                    </p>
                    <br />
                    <p className="description">
                      Warm today is helping Brilliantly achieve its vision of empowering breast
                      cancer survivors and previvors as they navigate the months and years after
                      surgery.
                    </p>
                    <br />
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default BrilliantlyWarm;
